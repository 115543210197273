@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'Gilroy-Dark';
  src: url('./fonts/Gilroy-ExtraBold.otf') format('otf');
}

@font-face {
  font-family: 'Gilroy-Light';
  src: url('./fonts/Gilroy-Light.otf') format('otf');
}




* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.bg-orange {
  background: #da0b00 !important;
}

body {
  font-family: 'Poppins', sans-serif;
}

.logo-img {
  height: 65px !important;
  width: 65px !important;
  object-fit: cover;
}

.right-img img {
  position: relative;
  z-index: 2;
}

button.btn.btn-primary {
  background: #002ffe !important;
}

.right-img-back {
  position: absolute;
  top: -80px;
  left: -25px;
  z-index: 1;
}

.form-control:focus {
  border: 1px solid #99D0D0;
  box-shadow: none !important;
}

.fs-60 {
  font-size: 60px;
}

.box-shadow {
  -webkit-box-shadow: 3px 3px 10px 3px #dddddd;
  -moz-box-shadow: 3px 3px 10px 3px #dddddd;
  box-shadow: 3px 3px 10px 3px #dddddd;
}

.search-box:focus {
  box-shadow: 3px 3px 10px 3px #dddddd !important;
}

.cursor {
  cursor: pointer;
}

.nlrp {
  padding-left: 0px;
  padding-right: 0px;
}

.nlrm {
  margin-left: 0px;
  margin-right: 0px;
}

h1 {
  font-size: 100px;
}

h2 {
  font-size: 50px;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 30px;
}

/**** Home Page ***/
.logo-area {
  display: block;
}

/* .logo {
  float: left;
} */

.logo img {
  width: 50px;
}

.comp-name {
  float: left;
  font-size: 18px;
  font-weight: 900;
  margin-top: 8px;
}

.sbg-head {
  display: block;
  float: left;
}

.sbg-head h2 {
  font-size: 48px;
  font-weight: 900;
}

.signup-form {
  display: block;
  float: left;
}

.hd-links {
  text-align: right;
  padding: 10px 25px;
}

.hd-links a {
  font-size: 16px;
  color: #da0b00;
  text-decoration: none;
  font-weight: 900;
}

.hd-links a:last-child {
  font-size: 16px;
  color: #da0b00;
  text-decoration: none;
  font-weight: 900;
  margin-left: 10px;
}

.hd-links a:hover {
  color: #000000;
  text-decoration: none;
}

.login-btn {
  background-color: #da0b00;
  color: #ffffff !important;
  padding: 5px 15px;
  border-radius: 25px;
  font-weight: 900;
}

.login-btn:hover {
  background-color: #000000;
  color: #ffffff !important;
  transition: 2s;
}

.signup-form .uname {
  width: 45%;
}

.signup-form .row {
  margin-bottom: 25px;
}

/*** Login Icon ***/

.rt-line {
  margin: 10px 0px;
}

.rt-line ul {
  margin: 0;
  padding: 0;
}

.rt-line ul li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.rt-line ul li:first-child {
  margin-left: 0px;
}

.rt-line ul li {
  display: inline-block;
  margin-left: 22px;
}

.starIcon:hover {
  cursor: pointer;
}

#theStars {
  /*margin: 0 auto;*/
  margin-left: 10px;
}



/*** Right Side Area Home ***/

.hm-right-side {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: end;
  background-image: url('../src/img/right-bg.png');
}

.hm-right-grey {
  /* max-height: 100%; */
  height: 100vh;
  width: 80%;
}

/* SLider */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/***** Home page Product Scroller ****/
/* Main Home Sidebar */
.main-home-sideBar-ul li:not(:first-child) {
  list-style: none;
  color: #7e7c79;
  font-weight: 500;
}

.main-home-sideBar-ul {
  list-style: none;
}

.main-home-sidebar-btn {
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  background: #da0b00;
  color: #fff;
}

.primary-btn {
  border: none;
  padding: 6px 8px;
  border-radius: 8px;
  background: #da0b00;
  color: #fff;
}

.main-story-box img {
  position: relative;
}

.round-line {
  background: #dbd6da;
  width: 32px;
  height: 3px;
  margin: 10px auto;
}

.glass {
  position: absolute;
  top: 7px;
  left: 7px;
}

.cross {
  position: absolute;
  right: 7px;
  top: 7px;
}

.home-main-img {
  max-width: 600px;
  background: #eaeaea;
  height: 300px;
  border-radius: 150px;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); /* semi-transparent white background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/***** Responsive Screens *****/


@media screen and (max-width: 1280px) {}

@media screen and (max-width: 1199px) {}

@media screen and (max-width: 1080px) {}

@media screen and (max-width: 1026px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 991px) {}

@media screen and (max-width: 900px) {}

@media screen and (max-width: 800px) {}

@media screen and (max-width: 768px) {}

@media (min-width: 768px) {}

@media screen and (max-width: 767px) {}

@media screen and (max-width: 767px) {}

@media (min-width: 768px) {}

@media screen and (max-width: 736px) {}

@media screen and (max-width: 688px) {}

@media screen and (max-width: 667px) {}

@media screen and (max-width: 640px) {}

@media screen and (max-width: 600px) {}

@media screen and (max-width: 568px) {}

@media screen and (max-width: 480px) {}

@media only screen and (max-width: 479px) {}

@media screen and (max-width: 440px) {}

@media screen and (max-width: 414px) {}

@media screen and (max-width: 384px) {}

@media screen and (max-width: 375px) {}

@media screen and (max-width: 360px) {}

@media screen and (max-width: 320px) {}

/* -- //Responsive code -- */

/* For WebKit-based browsers (Safari, Chrome) */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
}

/* Optional: You can style the track as well */
::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* Color of the scrollbar track */
}
