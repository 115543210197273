@font-face {
  font-family: magicSaturday;
  src: url("/public/MagicSaturday.ttf");
}

.book-container {
  display: flex;
  height: 100vh;
}

.left {
  backdrop-filter: blur(18px);
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
}

.book-detail-wrap-sub {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 95%;
  overflow: scroll;
  overflow-x: hidden;
  height: 100%;
  margin-right: 10px;
}

.book-detail-wrap-sub::-webkit-scrollbar {
  width: 12px;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.book-detail-wrap-sub::-webkit-scrollbar-track {
  width: 18px;
  border-radius: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.book-detail-wrap-sub::-webkit-scrollbar-thumb {
  margin-top: 30px;
  margin-bottom: 30px;
}

.book-detail-wrap-sub::-webkit-scrollbar-thumb {
  background-color: #fdc21f;
  border: 1px solid #fdc21f;
  border-radius: 20px;
  width: 5px;
}

.left-top {
  padding: 50px 40px;
}

.book-header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head-story {
  font-size: 35px;
  color: white;
  font-weight: bold;
}

@import url(https://fonts.googleapis.com/earlyaccess/notonaskharabic.css);

.text-story {
  font-family: sans-serif;
  font-size: 30px;
  margin-top: 10px;
  text-align: justify;
  color: white;
  font-weight: bold;
  font-family: sans-serif;
  background-color: rgb(26 28 27 / 50%);
  color: white;
  padding: 10px;
}

.text-story-urdu {
  font-family: "Noto Naskh Arabic", serif !important;
  direction: rtl;
}

.story-writter-container .story-wriiter {
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.story-writter-container .story-wriiter-name {
  line-height: 0px;
  font-weight: 700;
  color: white;
  font-weight: bold;
}

.read-func-container {
  display: flex;
  padding-bottom: 20px;
}

.replay-btn {
  margin-right: 10px;
  padding: 10px 10px;
  background-color: #ff6b0a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.resume-pause-btn {
  margin-left: 10px;
  padding: 10px 10px;
  background-color: #ff6b0a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.left-bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 120px;
  margin-bottom: 20px;
}

.range {
  font-size: 20px;
  font-weight: bold;
  padding: 0px 3px;
  color: white;
}

.round-button {
  border: 2px solid #dedede;
  background-color: #ffffff;
  width: max-content;
  padding: 0px 15px;
  height: 50px;
  border-radius: 25px;
  margin: 0px 5px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0.95px 0.95px 3.6px;
}

.round-button:hover {
  background-color: #ff6b0a;
  color: white;
}

.right {
  width: 20%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-image {
  width: 90%;
  height: 50px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 260px;
}

.label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found-story {
  height: 470px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.head-story-responsive {
  display: none;
}

.read-func-container-responsive {
  display: none;
}

@media (max-width: 770px) {
  .story-writter-container .story-wriiter {
    font-size: 20px;
  }

  .text-story {
    font-size: 20px;
  }

  .left-top {
    padding: 50px 10px;
  }

  .book-name-writer-responsive {
    margin-right: 15px;
  }
}

@media (max-width: 435px) {
  .story-writter-container .story-wriiter {
    font-size: 20px;
  }

  .text-story {
    font-size: 16px;
  }
}

@media (max-width: 650px) {
  .img-container .book-cover-responsive {
    width: 100%;
  }

  .img-container .dashboard-img-responsive {
    width: 100%;
  }

  .head-story {
    font-size: 26px;
  }

  .book-name-writer-responsive {
    margin-right: 12px;
  }

  .img-container {
    width: unset !important;
  }
}

@media (max-width: 550px) {
  .img-container-responsive {
    max-width: 100%;
    min-width: 100%;
    height: 500px !important;
    position: relative;
  }

  .read-func-container {
    display: none;
  }

  .read-func-container-responsive {
    display: flex;
    position: absolute;
    bottom: -35px;
    right: 35px;
  }



  .book-name-writer-responsive {
    display: none;
  }

  .head-story-responsive {
    display: flex;
    flex-direction: column;
    margin: 20px 0px 20px 0px;
  }

  .img-container .dashboard-img-responsive {
    max-width: 100% !important;
    min-width: 100% !important;
    height: 500px !important;
  }

  .img-container .book-cover-responsive {
    max-width: 100%;
    min-width: 100%;
    height: 500px !important;
  }

}


@media (max-width: 440px) {
  .img-container-responsive {
    height: 300px !important;
  }

  .img-container .dashboard-img-responsive {
    height: 300px !important;
  }

  .img-container .book-cover-responsive {
    height: 300px !important;
  }

}